/* App.css */
html,
body,
#root {
    height: 100%;
    margin: 0;
    padding: 0;
}
/*
.aspect-ratio-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 177.78%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aspect-ratio-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-aspect-ratio: 9/16) {
    .aspect-ratio-container {
        padding-bottom: 56.25%;
    }
}

@media (max-aspect-ratio: 8.1/16) {
    .aspect-ratio-container {
        padding-bottom: 62.5%;
    }
}

@media (min-aspect-ratio: 9/14.4) {
    .aspect-ratio-container {
        padding-bottom: 50%;
    }
}
*/